<template>
	<div>
		<div class="bode">
			<div class="bode_left">
				<div class="main">
					<div class="container f-cb">
						<div class="leMenu">
							<div class="title">
								<h1>{{categorydata.category}}</h1>
								<p></p>
							</div>
							<ul class="big">
								<li><a href="" class="act">{{categorydata.headline}}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="mess-w" v-if="details !== true">
				<div class="bode_right">
					<div class="headmassage">
						<a>{{categorydata.headline}}</a>
					</div>
					<div class="center1-lm2" id="containet" v-cloak>
						<ul id="pageMain" v-for="(item,key) in datafrom" :key="key">
							<li>
								<a target="_blank" :title="item.title" @click="opentitle(item)">{{item.title}}</a>
							</li>
						</ul>
					</div>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="current" :page-sizes="sizes" :page-size="size"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>

			<div class="mess-w" v-if="details">
				<div class="bode_right" style="padding-top: 20px;">
					<el-page-header @back="goBack" :content="headline">
					</el-page-header>
					<div
						style="text-align: center;padding-bottom: 25px;padding-top: 20px;font-weight: bold;font-size: 20px;">
						{{title}}
					</div>
					<div v-html="content" style="padding-top:10px;padding-bottom: 10px"></div>
					<div style="padding-top: 30px;text-align: right;padding-bottom: 25px;font-weight: 600;">{{time}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			handleSizeChange(val) {
				this.size = val
				this.$post("/industry/searchboxinfo", {
					title: this.searchvalues,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			},
			handleCurrentChange(val) {
				this.current = val
				this.$post("/industry/searchboxinfo", {
					title: this.searchvalues,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			},
			opentitle(data) {
				this.details = true
				this.title = data.title
				this.content = data.content
				this.time = data.creatTime
				this.headline = data.headline
			},
			goBack() {
				this.details = false
			}
		},
		data() {
			return {
				current: 1,
				sizes: [5, 10, 20, 50],
				size: 5,
				total: 0,
				categorydata: {},
				datafrom: [],
				searchvalues: "",
				title: '',
				content: '',
				time: '',
				details: false,
				headline: ''
			};
		},
		created() {
			this.details = false
			this.searchvalues = this.$route.query.searchvalue
			this.size = this.sizes[0]
			this.$post("/industry/searchboxinfo", {
				title: this.searchvalues,
				current: this.current,
				size: this.size
			}).then(resp => {
				if (resp.data === null) {
					this.$message({
						message: resp.msg,
						type: 'false'
					})
					this.$router.push("/pc")
				}
				this.datafrom = resp.data.records
				this.categorydata = resp.data.records[0]
				this.total = resp.data.total
			})
		},
		watch: {
			$route() {
				this.details = false
				this.searchvalues = this.$route.query.searchvalue
				this.size = this.sizes[0]
				this.$post("/industry/searchboxinfo", {
					title: this.searchvalues,
					current: this.current,
					size: this.size
				}).then(resp => {
					if (resp.data === null) {
						this.$message({
							message: resp.msg,
							type: 'false'
						})
						this.$router.push("/pc")
					}
					this.datafrom = resp.data.records
					this.categorydata = resp.data.records[0]
					this.total = resp.data.total
				})
			}
		}
	}
</script>

<style>
	@import '../../assets/css/cont.css';
	@import '../../assets/css/index.css';

	.center1-lm2 ul li {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 0 !important;
		width: 100%;
		box-sizing: border-box;
		margin-top: 19 px;
		font-size: 16px;
		color: #333;
		line-height: 16px;
		text-indent: 1 em;
	}

	#pageMain li {
		list-style: none;
		line-height: 22px;
	}

	.el-pagination {
		margin-top: 10px;
		text-align: center;
		bottom: 50px;
		white-space: nowrap;
		padding: 2px 5px;
		color: #303133;
		font-weight: 700;
	}

	.el-pagination[data-v-195f7b39] {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	[v-cloak] {
		display: none;
	}
</style>
